<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <!--页面主体-->
    <section class="main">
      <div class="container">
        <div class="stepbox three">
          <div class="line"></div>
          <div class="now"></div>
          <div class="step stepone ok">
            <span><i class="icon-stepone"></i></span>
            <p>1、选择票种</p>
          </div>
          <div class="step steptwo ok" v-if="$route.query.itineraryType == 1">
            <span><i class="icon-steptwo"></i></span>
            <p>2、选择预订消费日期填写游客信息</p>
          </div>
          <div class="step stepthree ok" v-if="$route.query.itineraryType == 1">
            <span><i class="icon-stepthree"></i></span>
            <p>3、提交行程单等待审核</p>
          </div>
          <div class="step stepfour" v-if="$route.query.itineraryType == 1">
            <span><i class="icon-stepfour"></i></span>
            <p>4、确认支付</p>
          </div>
          <div class="step five" v-if="$route.query.itineraryType == 1">
            <span><i class="icon-stepfive"></i></span>
            <p>5、购票成功</p>
          </div>

          <div class="step steptwo ok f_s" v-if="$route.query.itineraryType == 2">
            <span><i class="icon-steptwo"></i></span>
            <p>2、选择预订消费日期填写游客信息</p>
          </div>
          <div class="step stepthree f_s" v-if="$route.query.itineraryType == 2">
            <span><i class="icon-stepthree"></i></span>
            <p>3、提交预约单</p>
          </div>
          <div class="step five f_s" v-if="$route.query.itineraryType == 2">
            <span><i class="icon-stepfive"></i></span>
            <p>4、预约成功</p>
          </div>
        </div>
        <div class="box ticket">
          <div class="title"><span>预订票务信息</span></div>
          <div class="content">
            <!-- <p class="nodata"><img src="images/loading.gif" alt="数据加载中" title="数据加载中" /></p> -->
            <div class="databox">
              <div class="ticketstyle">
                <div class="total">票数小计<span>{{info.orderDetails.length}}张</span>小计<em>￥{{info.ticketItinerary.totalSellPrice ? Number(info.ticketItinerary.totalSellPrice/100).toFixed(2) : 0}}</em></div>
                <div class="name">票务总计</div>
              </div>
              <div class="ticketinfo">
                <table>
                  <thead>
                  <tr class="gray">
                    <th>商品名称</th>
                    <th>单价</th>
                    <th>预订数量</th>
                    <th>总价</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(itm,idx) in showArr" :key="idx" :class="idx%2 == 1 ? 'gray' : ''">
                    <td>{{itm.groupName}}</td>
                    <td>￥{{itm.sellPrice ? Number(itm.sellPrice/100).toFixed(2) : 0}}</td>
                    <td>{{itm.total}}</td>
                    <td>￥{{itm.sellPrice ? Number(itm.sellPrice/100*itm.total).toFixed(2) : 0}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="box order">
          <div class="title"><span>确认行程单</span></div>
          <div class="content">
            <div class="detail">
              <div class="item">
                <div class="itemname">预计消费日期</div>
                <div class="iteminfo">{{info.ticketItinerary.bookUseDate ? info.ticketItinerary.bookUseDate.split(' ')[0] : '--'}}</div>
              </div>
              <div class="item">
                <div class="itemname">入园时段</div>
                <div class="iteminfo">{{info.orderDetails[0].startTime + ' - ' + info.orderDetails[0].endTime}}</div>
              </div>
              <div class="item">
                <div class="itemname">预订人姓名</div>
                <div class="iteminfo">{{info.ticketItinerary.reservationPersonName}}</div>
              </div>
              <div class="item">
                <div class="itemname">预订人电话</div>
                <div class="iteminfo">{{info.ticketItinerary.reservationPersonTelephone}}</div>
              </div>
              <div class="item">
                <div class="itemname">票数总计</div>
                <div class="iteminfo">{{info.orderDetails.length}}</div>
              </div>
              <div class="item">
                <div class="itemname">金额总计</div>
                <div class="iteminfo">{{info.ticketItinerary.totalSellPrice ? Number(info.ticketItinerary.totalSellPrice/100).toFixed(2) : 0}}</div>
              </div>
            </div>
          </div>


          <div class="btns">
            <a href="javascript:;" title="确认提交" @click="submit" v-if="!submiting">确认提交</a>
            <a href="javascript:;" title="取消" class="cancel" @click="cancel">取消</a>
          </div>
          <div class="tipsinfo">
            <h1><i></i>温馨提示：</h1>
            <p>预约单支持现场窗口修改人数及支付;</p>
            <p>提交行程单后，工作人员会在1个小时内向预订人手机发送审核结果：</p>
            <p><strong>审核成功：请在60分钟内登录分销商开发平台，前往订单中心确认下单并完成支付，否则行程单将自动取消。</strong></p>
            <p><strong>审核失败：请前往分销商开发平台查看具体原因，重新修改提交行程单。</strong></p>
          </div>
        </div>
      </div>
    </section>
    <section class="popup" v-show="ticketLack">
      <div class="box">
        <div class="title">
          <a href="javascript:;" title="关闭" @click="ticketLack = false">关闭</a>
          <span>温馨提示</span>
        </div>
        <div class="content ticketlack">
          <div class="lack">
            <i></i>
            <p>【乐园门票】余票不足</p>
            <p>请选择其他票种购票!</p>
            <!-- <a href="javascript:;" @click="/ti">重新购票</a> -->
            <router-link :to="{path:'/ticket'}">重新购票</router-link>
            <a href="javascript:;" class="cancel" @click="ticketLack = false">关闭</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: "submitOrder",
    data() {
      return {
        ticketLack: false, // 显示余票不足
        info:{ticketItinerary:{},orderDetails:[{}]},
        showArr:[],
        submiting: false, // 提交中
        itineraryType:1,
      };
    },
    created(){
      this.getInfo();
    },
    methods: {
      getInfo(){
        this.$api.queryItineraryGroup({itineraryId :this.$route.query.id}).then(res => {
          this.info = res.data;
          this.info.orderDetails.map(item => {
            item.totalNum = 1;
          });
          let arr = JSON.parse(JSON.stringify(this.info.orderDetails));
          for(let i = 0;i<arr.length;i++){
            arr[i].total = 1;
            for(let j = i+1;j<arr.length;j++){
              if(arr[i].groupCommodityId == arr[j].groupCommodityId){
                arr[i].total ++
                arr.splice(j,1)
                j--
              }
            }
          }
          this.showArr = JSON.parse(JSON.stringify(arr));
        })

      },
      // 提交订单
      submit() {
        if (this.submiting) return;
        this.submiting = true;
        this.$api.itineraryApprove({itineraryId:this.$route.query.id}).then(res => {
          this.$message.success('提交成功');
          if(sessionStorage.getItem('itineraryType') == 1){
            this.$router.push({path:'/trip'});
          }else{
            sessionStorage.removeItem('itineraryType');
            this.$router.push({path:'/paySuccess',query:{itineraryType:2,orderNumber:this.$route.query.id}});

          }
          sessionStorage.removeItem('goodsInfo');
          sessionStorage.removeItem('saveInfo');
          sessionStorage.removeItem('timeCheck');
          sessionStorage.removeItem('time');
        }).catch(() => {
          this.submiting = false;
        })
      },
      // 取消提交
      cancel() {
        this.$router.go(-1)
      },
    }
  };
</script>

<style scoped>
  @import "../assets/css/step.css";
  @import "../assets/css/submitOrder.css";
</style>
